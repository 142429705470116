import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  background-color: #faf9f6;
  padding: 4em 1em;
`
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #faf9f6;
`
const Title = styled.h2`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
  font-size: 28px;
  color: black;
  text-align: center;
  padding: 50px 0;
  margin: 0 auto;
  font-weight: 600;
  line-height: 1.5;
`
const Description = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho&display=swap');
  font-family: 'Sawarabi Mincho', sans-serif;
  display: flex;
  justify-content: center;
  max-height: 350px;
  min-height: 100px;
  height: 100%;
  background-color: whitesmoke;
  padding: 2em;
  margin: 50px auto;
  max-width: 640px;
  width: 100%;
  @media screen and (max-width: 543px) {
    margin: 10px auto;
}
`
const PostText = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho&display=swap');
  font-family: 'Sawarabi Mincho', sans-serif;
  line-height: 2.5;
  word-wrap: break-word;
  overflow-wrap: break-word;
`
const Contents = styled.div`
  width: 100%;
  margin: 0 auto;
`
const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const InnerColumn = styled.div`
  max-width: 640px;
  width: 100%;
  padding: 0 2em;
`
const TagBox = styled.div`
  margin-top: 4rem;
`
const TagTitle = styled.h4`
`


export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <Container>
      {helmet || ''}
      <Contents>
        <Header>
          <Title>{title}</Title>
        </Header>
        <Description>{description}</Description>
        <Column>
          <InnerColumn>
            <PostText>
              <PostContent content={content} />
            </PostText>
            {tags && tags.length && (
              <TagBox>
                <TagTitle>Tags</TagTitle>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </TagBox>
            )}
          </InnerColumn>
        </Column>
      </Contents>
    </Container>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`
